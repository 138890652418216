// Import libraries
import React, { useEffect, useState } from 'react';

// Import utility functions
import { createGrantProposalInFirestore } from '../../utilityFunctions/firestoreUtilities'
import { deleteDoc, doc } from "firebase/firestore";
import db from '../../config/firebaseConfig'; 

// Import necessary Firestore functions
import { addDoc, collection, serverTimestamp, query, where, getDocs, updateDoc } from "firebase/firestore";

const ProposalsDashboard = ({ 
        authUser,
        membershipDetails,
        setCurrentProposal, 
        currentClientOrganization,
        currentProposal,        
        grantProposals,
        refreshGrantProposals,
        setCurrentProposalToManage,
        setShowAddMoreGrantsModal,
        setShowMagicFirstDraftModal,
        setShowManageProposalModal, 
        setShowPaywallModal,                                       
        
    }) => {    

    const [goalAmount, setGoalAmount] = useState(0); 

    // Fetch goal from Firestore when component mounts
    useEffect(() => {
        if (currentClientOrganization && currentClientOrganization.id) {
            const fetchGoal = async () => {
                try {
                    const goalsQuery = query(
                        collection(db, "goals"),
                        where("organizationId", "==", currentClientOrganization.id)
                    );
                    const querySnapshot = await getDocs(goalsQuery);

                    if (!querySnapshot.empty) {
                        const goalDoc = querySnapshot.docs[0];
                        setGoalAmount(goalDoc.data().goalAmount);
                    }
                } catch (error) {
                    console.error("Error fetching goal from Firestore: ", error);
                }
            };

            fetchGoal();
        }
    }, [currentClientOrganization]);

    // When user changes or no document ID currently set, fetch grant proposals for current user
    useEffect(() => {            
        refreshGrantProposals();
    }, [authUser, currentProposal === null]);

    // Main function handle when user requests to delete a grant document
    const handleDeleteGrantProposal = async (selectedProposal) => {
        if (window.confirm("Are you sure you want to delete this grant proposal?")) {            
            try {                                                                
                console.log("Deleting grant proposal in Firestore.");
                await deleteDoc(doc(db, "grantProposals", selectedProposal.id));            
                refreshGrantProposals()
            } catch (error) {
                console.error("Error removing document: ", error);
            }
        }
    };

    const handleEditGrantProposal = (proposal) => {
        setCurrentProposalToManage(proposal)
        setShowManageProposalModal(true) // REFACTOR: can this be removed and just use if currentProposalToManage is set?
        console.log(proposal)
    }

    const handleCreateNewProposal = async () => {
        const grantName = prompt("Enter a name for your new proposal:");
        if (grantName) {
            await createGrantProposalInFirestore(authUser, grantName, currentClientOrganization.id);
            refreshGrantProposals();
        }
    };

    // Main function to handle when user selects a document
    const handleGrantProposalSelect = (proposal) => {
        if (!membershipDetails) {
            setShowPaywallModal(true);
        } else {
            setCurrentProposal(proposal);
        }
    };    
    const totalWonAmount = grantProposals
        .filter(proposal => proposal.status === "Won" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalExpectedValue = grantProposals
        .filter(proposal => proposal.amount != null) // Filter out null amounts
        // .reduce((sum, proposal) => sum + Number(proposal.amount), 0);    
    // .filter(proposal => proposal.status !== "Won")    
        .reduce((sum, proposal) => sum + (Number(proposal.amount) * (Number(proposal.chance) / 100)), 0);

    const totalSubmittedAmount = grantProposals
        .filter(proposal => proposal.status === "Submitted")
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalInProgressAmount = grantProposals
        .filter(proposal => proposal.status === "In Progress" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalSavedAmount = grantProposals
        .filter(proposal => proposal.status === "Saved" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);


    const handleGoalClick = async () => {
        const newGoal = prompt("Enter a new goal amount:", goalAmount);
        if (newGoal !== null) {
            setGoalAmount(Number(newGoal));
            
            try {
                // Query to check if a goal already exists for the current organization
                const goalsQuery = query(
                    collection(db, "goals"),
                    where("organizationId", "==", currentClientOrganization.id)
                );
                const querySnapshot = await getDocs(goalsQuery);

                if (!querySnapshot.empty) {
                    // If a goal exists, update the first document found
                    const goalDoc = querySnapshot.docs[0];
                    await updateDoc(goalDoc.ref, {
                        goalAmount: Number(newGoal),
                        updatedAt: serverTimestamp() // Optionally update the timestamp
                    });
                    console.log("Goal updated in Firestore successfully.");
                } else {
                    // If no goal exists, create a new one
                    await addDoc(collection(db, "goals"), {
                        goalAmount: Number(newGoal),
                        organizationId: currentClientOrganization.id,
                        createdAt: serverTimestamp()
                    });
                    console.log("Goal saved to Firestore successfully.");
                }
            } catch (error) {
                console.error("Error saving or updating goal in Firestore: ", error);
            }
        }
    };

    return (
        <div className='p-12'>
            <div className='flex justify-between items-center'>
                <p className="text-3xl font-bold text-left">Overview</p>
                {/* <div className="flex space-x-4">
                    <button onClick={() => setShowAddMoreGrantsModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        Add More Grants
                    </button>
                </div> */}
            </div>
            

            {/* New section for hardcoded values */}
            <div className="flex space-x-4 mt-5 text-left">
                <div className="bg-indigo-500 text-white font-bold py-4 px-4 rounded w-1/6">
                    <p className="text-3xl">${totalWonAmount ? totalWonAmount.toLocaleString() : '0'}</p>
                    <p className="text-xs font-normal mt-2">Won</p>
                </div>
                <div 
                    className="bg-green-500 text-white font-bold py-4 px-4 rounded w-1/6"
                    onClick={handleGoalClick}
                    style={{ cursor: 'pointer' }} // Removed position: relative
                >
                    <p className="text-3xl">${goalAmount.toLocaleString()}</p>
                    <div className="flex justify-between mt-2">
                        <p className="text-xs font-normal">Goal</p>
                        <span className="text-xs">EDIT</span>
                    </div>
                    
                </div>

                <div className="bg-green-500 text-white font-bold py-4 px-4 rounded w-1/6">
                    <p className="text-3xl">${totalExpectedValue ? totalExpectedValue.toLocaleString() : '0'}</p>
                    <p className="text-xs font-normal mt-2">Expected</p>
                </div>
                <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/6">
                    <p className="text-3xl">${totalSubmittedAmount ? totalSubmittedAmount.toLocaleString() : '0'}</p>
                    <p className="text-xs font-normal mt-2">Submitted</p>
                </div>
                <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/6">
                    <p className="text-3xl">${totalInProgressAmount ? totalInProgressAmount.toLocaleString() : '0'}</p>
                    <p className="text-xs font-normal mt-2">In Progress</p>
                </div>
                <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/6">
                    <p className="text-3xl">${totalSavedAmount ? totalSavedAmount.toLocaleString() : '0'}</p>
                    <p className="text-xs font-normal mt-2">Saved</p>
                </div>
            </div>

            <div className="flex justify-between items-center mt-10">
                <p className="text-3xl font-bold text-left">Your Grants</p>
                <div className="flex space-x-4">
                    <button onClick={() => setShowAddMoreGrantsModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        Add More Grants
                    </button>
                    {/* <button onClick={handleCreateNewProposal} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                        Create Blank Draft
                    </button>
                    <button onClick={() => setShowMagicFirstDraftModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        🪄 Create Magic First Draft
                    </button> */}
                </div>
            </div>            

            <div className=''>                
                <table className='w-full text-left mt-5 text-xs'>
                    <thead>
                        <tr>
                            <th className='px-2 py-2 text-gray-400 text-xs semibold' style={{ width: '20%' }}>Opportunity Name</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Updated</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Created</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Status</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Notes</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Amount ($)</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Chance (%)</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Expected ($)</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '10%' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {grantProposals.map((proposal) => (
                            <tr key={proposal.id} onClick={() => handleGrantProposalSelect(proposal)} className="hover:bg-gray-200" style={{ cursor: 'pointer' }}>
                                <td className='px-2 py-2 font-bold'>{proposal.name}</td>
                                <td className='px-4 py-2'>{new Date(proposal.updatedAt.seconds * 1000).toLocaleString()}</td>
                                <td className='px-4 py-2'>{new Date(proposal.createdAt.seconds * 1000).toLocaleString()}</td>
                                <td className='px-4 py-2'>{proposal.status}</td>
                                <td className='px-4 py-2'>
                                    {proposal.notes ? (proposal.notes.length > 20 ? `${proposal.notes.substring(0, 20)}...` : proposal.notes) : ''}
                                </td>
                                <td className='px-4 py-2'>{proposal.amount ? `$${parseInt(proposal.amount).toLocaleString()}` : ''}</td>
                                <td className='px-4 py-2'>{proposal.chance ? `${proposal.chance}%` : ''}</td>
                                <td className='px-4 py-2'>{proposal.amount && proposal.chance ? `$${(proposal.amount * (proposal.chance / 100)).toLocaleString()}` : ''}</td>
                                <td className='px-4 py-2 flex'>
                                    <button 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditGrantProposal(proposal);
                                        }} 
                                        className="bg-gray-300 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded"
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteGrantProposal(proposal);
                                        }} 
                                        className="bg-gray-300 hover:bg-red-500 text-white font-bold py-1 px-2 rounded ml-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProposalsDashboard;
