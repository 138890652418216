import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import db, { auth } from '../../config/firebaseConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { stripHtmlTags } from '../../utilityFunctions/uxUtilities';
import PaywallComponent from '../HelperComponents/PaywallComponent';
import plusIcon from '../../assets/plus.png'
import { createGrantProposalInFirestore } from '../../utilityFunctions/firestoreUtilities';

const ProgramDashboard = ({
    currentProgram,
    membershipDetails,
    organizationPrograms,
    setCurrentProgram,
    setProgramToModify,
    setShowCreateProgramModal,
    setShowOpportunityAndFunderModal,
    setSelectedOpportunity,
    setShowPaywallModal,
    authUser,
    currentClientOrganization
}) => {

    const [opportunities, setOpportunities] = useState([]);
    const [leadScores, setLeadScores] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [opportunitiesView, setOpportunitiesView] = useState(membershipDetails ? 'list' : 'card');

    useEffect(() => {
        if (!currentProgram && organizationPrograms.length > 0) {
            console.log("Organization Programs: ", organizationPrograms)
            console.log("Setting current program to: ", organizationPrograms[0])
            setCurrentProgram(organizationPrograms[0]);
        }
    }, [currentProgram, organizationPrograms]);

    useEffect(() => {
        if (currentProgram) {
            fetchOpportunityLeadScoresForProgram(currentProgram.id);
        }
    }, [currentProgram]);
    
    useEffect(() => {
        fetchProcessedGrantOpportunititesFromFirestore();
        // fetchOpportunityLeadScoresForProgram(currentProgram.id);
    }, []);

    // Methods for Program Selection Header
    useEffect(() => {
        console.log("Organization Programs:", organizationPrograms);
    }, [organizationPrograms]);
    const handleCreateProgramButtonClicked = () => {
        if (!membershipDetails) {
            setShowPaywallModal(true);
        } else {
            console.log("Create program")
            setProgramToModify(null)
            setShowCreateProgramModal(true)
        }
    };

    const handleProgramSelected = (program) => {
        console.log("Program selected: ", program)
        setCurrentProgram(program)
        // setCurrentView('programDashboard')
    };

    useEffect(() => {
        console.log("OPP from UE: ", opportunities)
        console.log("LS from UE: ", leadScores)
        if (opportunities.length) {
            const combinedData = opportunities.map(opportunity => {
                const leadScore = leadScores.find(score => score.opportunityId === opportunity.id);
                
                // Check if deadlines array exists
                const now = new Date();
                const deadlines = opportunity.deadlines ? opportunity.deadlines.map(d => ({ ...d, date: new Date(d.date) })) : [];
                const nextUpcomingDeadline = deadlines.filter(d => d.date > now).sort((a, b) => a.date - b.date)[0] || null;
                const mostRecentlyPassedDeadline = deadlines.filter(d => d.date < now).sort((a, b) => b.date - a.date)[0] || null;

                return { 
                    ...opportunity, 
                    leadScore: leadScore ? leadScore.categorization.replace(/^(Class: `\s*|`)|`$/g, '') : null,
                    leadScoreResponse: leadScore ? leadScore.response : "N/A",
                    leadScoreCreationTimestamp: leadScore ? leadScore.createdAt : null,
                    nextUpcomingDeadline: nextUpcomingDeadline ? nextUpcomingDeadline.date : null,
                    nextUpcomingDeadlineTitle: nextUpcomingDeadline ? nextUpcomingDeadline.title : 'N/A',
                    mostRecentlyPassedDeadline: mostRecentlyPassedDeadline ? mostRecentlyPassedDeadline.date : null,
                    mostRecentlyPassedDeadlineTitle: mostRecentlyPassedDeadline ? mostRecentlyPassedDeadline.title : 'N/A'
                };
            });
            console.log("CDCD", combinedData)
            setRowData(combinedData);
        } else {
            console.log("Missing opportunities or lead scores")
        }
    }, [opportunities, leadScores]);

    // Component Methods

    const handleRowClick = (data) => {
        setSelectedOpportunity(data)
        setShowOpportunityAndFunderModal(true)
    };

    const handleManageProgramButtonClicked = () => {
        setProgramToModify(currentProgram)
        setShowCreateProgramModal(true);        
    };

    // const fetchUserOpportunityLeadScores = async () => {
    //     console.log("Fetching user's opportunity lead scores from Firestore.")
    //     try {
    //         const q = query(collection(db, "opportunityLeadScores"), where("userId", "==", auth.currentUser.uid));
    //         const querySnapshot = await getDocs(q);
    //         const leadScoresArray = [];
    //         querySnapshot.forEach(doc => {
    //             leadScoresArray.push({ id: doc.id, ...doc.data() });
    //         });
    //         console.log(leadScoresArray)
    //         setLeadScores(leadScoresArray);
    //     } catch (error) {
    //         console.error("Error fetching opportunities:", error);
    //     }
    // };

    const fetchOpportunityLeadScoresForProgram = async (programId) => {
        console.log("Fetching program's opportunity lead scores from Firestore.")
        try {
            const q = query(collection(db, "opportunityLeadScores"), where("programId", "==", programId));
            const querySnapshot = await getDocs(q);
            const leadScoresArray = [];
            querySnapshot.forEach(doc => {
                leadScoresArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(leadScoresArray)
            setLeadScores(leadScoresArray);
        } catch (error) {
            console.error("Error fetching opportunities:", error);
        }
    };

    const fetchProcessedGrantOpportunititesFromFirestore = async () => {
        console.log("Fetching enriched grant opportunities from Firestore.")
        try {
            const q = query(collection(db, "grantOpportunities"), where("status", "==", "enriched"));
            const querySnapshot = await getDocs(q);
            const opportunitiesArray = [];
            querySnapshot.forEach(doc => {
                opportunitiesArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(opportunitiesArray)
            setOpportunities(opportunitiesArray);
        } catch (error) {
            console.error("Error fetching opportunities:", error);
        }
    };

    const columnDefs = [
        { 
            headerName: "Discover Date", 
            field: "leadScoreCreationTimestamp", 
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => {
                if (value && value.toDate) {
                    return value.toDate().toLocaleDateString();
                }
                return 'N/A';
            }
        },
        { 
            headerName: "Grant Name", 
            field: "grantName", 
            filter: 'agTextColumnFilter',
        },
        { 
            headerName: "Lead Score", 
            field: "leadScore", 
            filter: 'agTextColumnFilter', 
            valueFormatter: ({ value }) => value !== null ? value : 'N/A'
        },
        { 
            headerName: "Score Notes", 
            field: "leadScoreResponse", 
            filter: 'agTextColumnFilter', 
            // valueFormatter: ({ value }) => value !== null ? value : 'N/A'
        },

        { 
            headerName: "Overview", 
            field: "grantOverview", 
            filter: 'agTextColumnFilter',
            autoHeight: true,
            cellRenderer: props => stripHtmlTags(props.value)
        },
        { 
            headerName: "Grant Size Min", 
            field: "grantSizeMin", 
            filter: 'agNumberColumnFilter', 
            valueFormatter: ({ value }) => {
                if (value === null || value === undefined || isNaN(value)) {
                    return 'N/A';
                }
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    return 'N/A';
                }
                return `$${value.toLocaleString()}`;
            },
            valueParser: ({ newValue }) => {
                return parseInt(newValue, 10) || 0;
            }
        },
        { 
            headerName: "Grant Size Max", 
            field: "grantSizeMax", 
            filter: 'agNumberColumnFilter', 
            valueFormatter: ({ value }) => {
                if (value === null || value === undefined || isNaN(value)) {
                    return 'N/A';
                }
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    return 'N/A';
                }
                return `$${value.toLocaleString()}`;
            },
            valueParser: ({ newValue }) => {
                return parseInt(newValue, 10) || 0;
            }
        },
        { 
            headerName: 'Website', 
            field: 'url', 
            cellRenderer: props => {
                let website = props.value;
                if (typeof website === 'string' && !website.startsWith('https://')) {
                    website = `https://${website}`;
                }
                return <a className='text-blue-500' href={website} target="_blank">{props.value}</a>;
            }
        },
        { 
            headerName: "Next Deadline", 
            field: "nextUpcomingDeadline", 
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => value ? value.toLocaleDateString() : 'N/A'
        },
        { 
            headerName: "Deadline Notes", 
            field: "nextUpcomingDeadlineTitle", 
            // filter: 'agTextColumnFilter',
            valueFormatter: ({ value }) => value
        },
        { 
            headerName: "Last Deadline", 
            field: "mostRecentlyPassedDeadline", 
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => value ? value.toLocaleDateString() : 'N/A'
        },
        { 
            headerName: "Last Deadline Notes", 
            field: "mostRecentlyPassedDeadlineTitle", 
            // filter: 'agTextColumnFilter',
            valueFormatter: ({ value }) => value
        },
    ];
    
    const handleSaveButtonClick = async (event, selectedGrant) => {
        event.stopPropagation();
        if (!membershipDetails) {
            setShowPaywallModal(true);
        } else {
            console.log("Selected Grant Details:", selectedGrant);
            await createGrantProposalInFirestore(authUser, selectedGrant.grantName, currentClientOrganization.id);
            alert("Successfully saved to Dashboard");
        }
    };

    return (
        <div className='p-12'>
            {/* <p></p> */}
            {/* Program Selection Header */}
            <div className='flex space-x-2 mt-7'>
                <select 
                    className='py-1 rounded-lg hover:bg-gray-200 flex items-center border border-gray-200 text-gray-700 py-3 px-4 pr-10 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    value={currentProgram ? currentProgram.id : ''}
                    onChange={(e) => {
                        const selectedProgramId = e.target.value;
                        const selectedProgram = organizationPrograms.find(program => program.id === selectedProgramId);
                        handleProgramSelected(selectedProgram);
                    }}
                >
                    {organizationPrograms && organizationPrograms.length > 0 && organizationPrograms.map(program => (
                        <option key={program.id} value={program.id}>
                            {program.programName}
                        </option>
                    ))}
                </select>
                <button 
                    className='bg-gray-200 p-2 text-sm rounded-lg'
                    onClick={handleCreateProgramButtonClicked}
                >
                    Create New
                </button>
                <button className='bg-gray-200 p-2 text-sm rounded-lg' onClick={handleManageProgramButtonClicked}>Manage</button>
            </div>
            {/* <div className="flex mt-10 space-x-8">
                {currentProgram && <p className="text-3xl font-bold text-left">{currentProgram.programName}</p>}
                <button className='bg-gray-200 p-2 text-sm rounded-lg' onClick={handleManageProgramButtonClicked}>Manage</button>
            </div> */}
            {/* <p className='italic text-left text-gray-500'>Active grant opportunities by private foundations looking to fund nonprofits</p> */}
            
            <p className='text-left mt-8 text-sm font-bold text-gray-500'>PROGRAM DETAILS</p>
            <div className='bg-gray-200 rounded text-left p-3 text-sm my-2'>
                <p><span className="text-gray-500">Overview:</span> {stripHtmlTags(currentProgram?.overview ?? 'N/A')}</p>
                <p><span className="text-gray-500">Population Served:</span> {stripHtmlTags(currentProgram?.populationsServed ?? 'N/A')}</p>
                <p><span className="text-gray-500">Areas Served:</span> {stripHtmlTags(currentProgram?.areasServed ?? 'N/A')}</p>
                {/* <p><span className="text-gray-500">Mission:</span> {stripHtmlTags(currentProgram?.mission ?? 'N/A')}</p> */}
                
            </div>

            {/* Grant Opportunities */}
            <div className='flex justify-between items-center mt-7'>
                <p className='text-3xl font-bold text-black'>Grant Opportunities</p>
                {membershipDetails && (
                    <div className='flex space-x-4'>
                        <button 
                            className={`py-2 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-300 border border-gray-300 text-gray-700 ${opportunitiesView === 'list' ? 'bg-gray-300' : 'bg-white'}`}
                            onClick={() => setOpportunitiesView('list')}
                        >
                            List View
                        </button>
                        <button 
                            className={`py-2 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-300 border border-gray-300 text-gray-700 ${opportunitiesView === 'card' ? 'bg-gray-300' : 'bg-white'}`}
                            onClick={() => setOpportunitiesView('card')}
                        >
                            Card View
                        </button>
                    </div>
                )}
            </div>
            {opportunitiesView === 'list' ? (
                <div className="w-full text-left mt-16 ag-theme-alpine" style={{ width: '100%' }}>
                    <style>
                        {`
                            .ag-theme-alpine .ag-cell {
                                user-select: text;
                            }
                            .ag-theme-alpine .ag-row:hover {
                                cursor: pointer;
                            }
                        `}
                    </style>
                    <p className='text-left mt-8 mb-2 text-sm font-bold text-gray-500'>GRANT OPPORTUNITIES</p>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowClicked={(event) => handleRowClick(event.data)}
                        domLayout='autoHeight'
                    />
                </div>
            ) : (
                <div className="w-full text-left mt-16" style={{ width: '100%' }}>
                    {/* <p className='text-left mt-8 mb-2 text-sm font-bold text-gray-500'>GRANT OPPORTUNITIES</p> */}
                    <div className="grid grid-cols-3 gap-4">
                        {rowData.slice(0, membershipDetails ? rowData.length : 6).map((data, index) => (
                            <div 
                                key={index} 
                                className="bg-white rounded-lg shadow-md cursor-pointer flex flex-col justify-between"
                                onClick={() => handleRowClick(data)}
                            >
                                <p 
                                    className={`text-sm px-4 py-2 rounded-t-lg ${
                                        data.leadScore
                                            ? data.leadScore.includes("Bad")
                                                ? 'bg-red-900 text-white'
                                                : data.leadScore.includes("Good")
                                                ? 'bg-green-500 text-white'
                                                : membershipDetails
                                                ? 'bg-gray-200 text-gray-500'
                                                : 'bg-indigo-500 text-white'
                                            : 'bg-gray-200 text-gray-500' // Default style if leadScore is null
                                    }`}
                                >
                                    Match Score: {membershipDetails ? data.leadScore : '🔓 Premium Plan Only'}
                                </p>
                                <div className='p-4 flex-grow'>
                                    <p className="text-lg font-bold">{data.grantName}</p>                                    
                                    <p className="text-sm text-gray-500">{stripHtmlTags(data.grantOverview).length > 300 ? stripHtmlTags(data.grantOverview).substring(0, 300) + '...' : stripHtmlTags(data.grantOverview)}</p>
                                </div>
                                <div className="px-4 py-4">
                                    <button 
                                        className="bg-gray-500 text-white text-sm py-2 px-4 rounded" 
                                        onClick={(event) => handleSaveButtonClick(event, data)}
                                    >
                                        🤍 Save
                                    </button>
                                    <button 
                                        className="ml-2 text-gray-500 text-sm py-2 px-4 rounded" 
                                        // onClick={(event) => {
                                        //     event.stopPropagation();
                                        //     console.log('Learn more button clicked');
                                        // }}
                                    >
                                        Learn more
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!membershipDetails && rowData.length > 6 && (
                        <div className='flex justify-center'>
                            <button 
                                className="mt-12 bg-gray-500 text-white text-lg py-2 px-4 rounded"
                                onClick={() => setShowPaywallModal(true)}
                            >
                                See More
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ProgramDashboard;