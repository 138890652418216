import React from 'react';
import daisySprite from '../assets/daisySprite.png';
import defaultAvatar from '../assets/defaultAvatar.jpg';

function HomeChat({
    organizationPrograms,
    setCurrentProgram,
    setCurrentView,     
    setShowKnowledgeBaseModal
}) {
    return (
        <div className='p-40'>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img src={daisySprite} alt="avatar" style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '50%' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='text-xs text-gray-600 text-left mb-1 ml-1'>Daisy</span>
                    <div className='p-4' style={{ background: '#f1f1f1', borderRadius: '10px' }}>
                        How can I help you today?
                    </div>
                </div>
            </div>
            <div className='flex items-start justify-end'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='text-xs text-right text-gray-600 mb-1 mr-1'>You</span>
                    <div className='bg-gray-400 text-left p-4 rounded-lg flex flex-col gap-2.5'>
                        <button className="hover:bg-indigo-500 hover:text-white rounded-lg p-2" onClick={() => { setCurrentProgram(organizationPrograms[0]); setCurrentView('programDashboard'); }}>Show me active grant opportunities</button>
                        <button className="hover:bg-indigo-500 hover:text-white rounded-lg p-2" onClick={() => setCurrentView('awardsDatabase')}>Help me find "good fit" funders </button>
                        <button className="hover:bg-indigo-500 hover:text-white rounded-lg p-2" onClick={() => setCurrentView('proposalsDashboard')}> Help me write a grant proposal</button>
                        <button className="hover:bg-indigo-500 hover:text-white rounded-lg p-2" onClick={() => setShowKnowledgeBaseModal(true)}>Add to your knowledge base</button>
                    </div>
                </div>
                
                <img src={defaultAvatar} alt="avatar" style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
            </div>
        </div>
    );
}

export default HomeChat;