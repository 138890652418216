import React, { useEffect } from 'react';

const OpportunityProfile = ({ 
    selectedOpportunity, 
    generateMagicFirstDraft,
    membershipDetails,
    setShowPaywallModal,
    setShowOpportunityAndFunderModal
}) => {
    useEffect(() => {
        console.log('Selected Opportunity:', selectedOpportunity);
        console.log('Membership Details:', membershipDetails); // Added console log for membershipDetails
    }, [selectedOpportunity, membershipDetails]);

    // Group deadlines by cycleId
    const groupDeadlinesByCycle = (deadlines) => {
        return deadlines.reduce((acc, deadline) => {
            const { cycleId, date, title } = deadline;
            if (!cycleId || !date || !title) {
                // Skip this deadline if cycleId, date, or title is null or an empty string
                return acc;
            }
            if (!acc[cycleId]) {
                acc[cycleId] = [];
            }
            acc[cycleId].push({ date, title });
            return acc;
        }, {});
    };

    const groupedDeadlines = selectedOpportunity && selectedOpportunity.deadlines && selectedOpportunity.deadlines.length > 0 
        ? groupDeadlinesByCycle(selectedOpportunity.deadlines) 
        : {};

    return (
        selectedOpportunity ? (
            <div className='p-12'>
                <div className='text-sm'>
                    <div className="ml-6 mt-12 text-3xl font-bold">{selectedOpportunity.grantName}</div>
                    <div className="ml-6 mt-4 mb-12"><a className='text-blue-800' href={selectedOpportunity.url} target="_blank" rel="noopener noreferrer">Visit Website</a></div>
                    
                    {/* Score Div */}
                    <div className={`m-6 ${
                        membershipDetails 
                            ? selectedOpportunity.leadScore.includes('Bad') 
                                ? 'bg-red-100' 
                                : selectedOpportunity.leadScore.includes('Good') 
                                    ? 'bg-green-200' 
                                    : 'bg-orange-100' 
                            : 'bg-indigo-500 text-white'
                    } p-6 rounded-lg`}>
                        <p className='font-bold text-lg'>{membershipDetails ? selectedOpportunity.leadScore : '🔓 Match score for Premium Plan only'}</p>
                        <p>{membershipDetails ? selectedOpportunity.leadScoreResponse : ''}</p>
                    </div>
                    <div className="m-6"><strong>Grant Overview</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.grantOverview }} /></div>
                    <div className="m-6"><strong>Eligibility Criteria:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.eligibilityCriteria }} /></div>
                    <div className="m-6"><strong>Application Requirements:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.applicationRequirements }} /></div>
                    <div className="m-6"><strong>Grant Size Range:</strong><br />${Number(selectedOpportunity.grantSizeMin).toLocaleString('en-US', { minimumFractionDigits: 0 })} - ${Number(selectedOpportunity.grantSizeMax).toLocaleString('en-US', { minimumFractionDigits: 0 })}</div>
                    
                    {/* Grouped Deadlines */}
                    {selectedOpportunity && selectedOpportunity.deadlines && selectedOpportunity.deadlines.length > 0 && (
                        <div className="m-6">
                            <strong>Deadlines:</strong>
                            {(() => {
                                const allDeadlines = Object.values(groupedDeadlines).flat();
                                const now = new Date();
                                const nextDeadline = allDeadlines
                                    .filter(deadline => new Date(deadline.date) > now)
                                    .sort((a, b) => new Date(a.date) - new Date(b.date))[0];

                                return Object.keys(groupedDeadlines).map(cycleId => (
                                    <div key={cycleId} className="mt-4 bg-gray-100 p-4 rounded-lg">
                                        <em>Cycle {cycleId}</em>
                                        <ul className='mt-4'>
                                            {groupedDeadlines[cycleId].map((deadline, index) => {
                                                const deadlineDate = new Date(deadline.date);
                                                const isPastDeadline = deadlineDate < now;
                                                const isNextDeadline = nextDeadline && deadlineDate.getTime() === new Date(nextDeadline.date).getTime();
                                                return (
                                                    <li key={index}>
                                                        {deadline.date}: {deadline.title}
                                                        {isPastDeadline ? <span style={{color: 'red'}}> (Passed)</span> : ''}
                                                        {isNextDeadline ? <span style={{color: 'blue'}}> (Next)</span> : ''}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ));
                            })()}
                        </div>
                    )}

                    {/* <div className="m-6"><strong>Important Dates:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.importantDates }} /></div> */}
                    {/* Show Other Timing Information only if additionalTimingInfo is not null/empty */}
                    {selectedOpportunity.additionalTimingInfo && (
                        <div className="m-6">
                            <strong>Other Timing Information:</strong><br />
                            <span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.additionalTimingInfo }} />
                        </div>
                    )}
                    <div className="m-6"><strong>Contact Info:</strong><br />{selectedOpportunity.contactInfo}</div>
                </div>
                {/* <div className="m-6">
                    {membershipDetails ? (
                        <button className="bg-indigo-500 hover:bg-primary-dark text-white font-bold py-2 px-4 rounded" onClick={() => generateMagicFirstDraft(selectedOpportunity.applicationRequirements, selectedOpportunity.grantName)}>
                            🪄 Generate Magic First Draft
                        </button>
                    ) : (
                        <button className="bg-indigo-500 hover:bg-primary-dark text-white font-bold py-2 px-4 rounded" onClick={() => {setShowOpportunityAndFunderModal(false); setShowPaywallModal(true);}}>
                            🪄 Generate Magic First Draft
                        </button>
                    )}
                </div> */}
                <p className='ml-6 italic text-xs text-gray-500'>
                    Created at: {selectedOpportunity.createdAt ? new Date(selectedOpportunity.createdAt.toDate()).toLocaleDateString() : 'N/A'}. 
                    Last updated: {selectedOpportunity.updatedAt ? new Date(selectedOpportunity.updatedAt.toDate()).toLocaleDateString() : 'N/A'}
                </p>
            </div>
        ) : (
            <div className='p-24 text-center flex items-center justify-center text-gray-500'>
                No opportunity selected
            </div>
        )
    );
};

export default OpportunityProfile;
