    import React, { useContext, useEffect } from 'react';
    import { AuthContext } from '../AuthContext';
    import { Link } from 'react-router-dom';
    import downArrow from '../assets/down-arrow.png';
    import plusIcon from '../assets/plus.png'



    const SideNavBar = ({ 
        currentClientOrganization,
        currentView,
        organizationPrograms,
        setCurrentProgram,
        setCurrentView, 
        setProgramToModify,
        setShowClientOrganizationsPickerModal,
        setShowCreateProgramModal,
        setShowKnowledgeBaseModal,    
    }) => {

        const { authUser, handleSignOut } = useContext(AuthContext);    

        const handleOrganizationButtonClicked = () => {
            setShowClientOrganizationsPickerModal(true)
        };

        const handleProgramSelected = () => {
            console.log("Organization Programs:", organizationPrograms)
            // console.log("Program selected: ", program)
            setCurrentProgram(organizationPrograms[0])
            setCurrentView('programDashboard')
        };
        
        return (
            <div className='bg-gray-300 p-6 text-left' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '300px', height: '100vh' }}>
                <button className='text-2xl mt-6' style={{fontStyle: 'cursive', fontWeight: '900'}} onClick={() => setCurrentView('proposalsDashboard')}>🪄 GrantMagic</button>
                <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Change-Log-30bcd234d85542a5bd30ca73f2d9fcb9?pvs=4" className='italic text-gray-700' target="_blank" rel="noopener noreferrer">Version 0.3.2 (Beta)</a>
                {authUser ? (
                    <div className='w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>                   
                        
                        {/* Current Org Button */}
                        {currentClientOrganization && 
                            <button onClick={handleOrganizationButtonClicked} className='p-2 rounded-lg w-full hover:bg-gray-200 flex items-center mt-7'>
                                <div className='bg-gray-500 rounded-lg text-xs py-1 px-2'>{currentClientOrganization.name[0].toUpperCase()}</div>
                                <h2 className="font-bold ml-2">{currentClientOrganization.name}</h2>
                                <img src={downArrow} alt="down-carrot-black" style={{width: '20px', height: '20px', marginLeft: 'auto'}} />
                            </button>
                        }

                        {/* <div className='flex space-x-2 mt-7'>
                            <h2 className="font-bold text-xl">Programs</h2>
                            <button className='bg-gray-600 rounded-lg mt-1' style={{width: '19px', height: '19px'}} onClick={handleCreateProgramButtonClicked} >
                                <img className='p-1' src={plusIcon} alt="plusIcon"/>
                            </button>                             
                        </div>
                        
                        
                        {organizationPrograms && organizationPrograms.length > 0 && organizationPrograms.map(program => (
                            <button key={program.id} onClick={() => handleProgramSelected(program)} className='mt-2 py-1 rounded-lg w-full hover:bg-gray-200 hover:ml-1 hover:pl-2 flex items-center'>
                                <h3 className="text-left text-sm">{program.programName}</h3>
                            </button>
                        ))} */}
                        
                        <div className="w-full mt-8 text-2xl">
                            <button 
                                className={`w-full py-3 text-left ${currentView === 'proposalsDashboard' ? 'font-bold' : ''}`} 
                                onClick={() => setCurrentView('proposalsDashboard')}
                            >
                                🏠  Dashboard
                            </button>
                            <button className={`w-full py-3 text-left ${currentView === 'programDashboard' ? 'font-bold' : ''}`} onClick={() => handleProgramSelected()}>🎁 Grant Database</button>
                            <button className={`w-full py-3 text-left ${currentView === 'awardsDatabase' ? 'font-bold' : ''}`} onClick={() => setCurrentView('awardsDatabase')}>🔍 Award Database</button>                            
                            {/* <button className='w-full py-3 text-left' onClick={() => setShowKnowledgeBaseModal(true)}>🤖  Assistant</button> */}
                            <button className={`w-full py-3 text-left ${currentView === 'assistantPage' ? 'font-bold' : ''}`} onClick={() => setCurrentView('assistantPage')}>🤖 Assistant</button>                            
                        </div>
                        
                        <div className='w-full flex flex-col text-md mt-6 text-gray-700 '>
                            <button className='mb-1 text-left hover:text-gray-900' onClick={() => setCurrentView('accountSettings')}>Account</button>
                            <a className='mb-1 hover:text-gray-900' href="https://grantmagic.featurebase.app/"  target="_blank" rel="noopener noreferrer">Feature Requests</a>                    
                            <a className='mb-1 hover:text-gray-900' href="https://grantmagic.featurebase.app/roadmap" target="_blank" rel="noopener noreferrer">Product Roadmap</a>                                        
                            <a className='mb-1 hover:text-gray-900' href="https://tally.so/r/mDVa5j" target="_blank" rel="noopener noreferrer">Support</a>                    
                            <a className='mb-1 hover:text-gray-900' href="#" onClick={handleSignOut} >Logout</a>    
                        </div>
                        
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <h2 className="font-bold mt-10">Settings</h2>
                        <a href="https://tally.so/r/mDVa5j" className="" target="_blank" rel="noopener noreferrer">Support</a>
                        <Link to="/signup" className="">Sign Up</Link>          
                        <Link to="/login" className="">Log In</Link>
                    </div>
                )}
            </div>
        );
    };

    export default SideNavBar;
