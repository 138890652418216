import React, { useState } from 'react';
import { createGrantProposalInFirestore } from '../../utilityFunctions/firestoreUtilities'


const AddMoreGrantsModal = ({
    authUser,
    currentClientOrganization,
    refreshGrantProposals,
    setShowAddMoreGrantsModal,
    setCurrentView
}) => {
    
    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowAddMoreGrantsModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '60vw', height: '50vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500 p-4">
                    <p className='text-3xl font-bold'>Add More Grants</p>                                        
                </div>
                
                {/* Body */}
                <div className="mt-4">
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => { setCurrentView('programDashboard'); setShowAddMoreGrantsModal(false); }}>
                        <h2 className="text-xl font-bold">🎁 Browse Grant Opportunities ➡️</h2>
                        <p>Browse active grant opportunities that your AI assistant has found for your nonprofit</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => { setCurrentView('awardsDatabase'); setShowAddMoreGrantsModal(false); }}>
                        <h2 className="text-xl font-bold"> 🔍 Research Past Awards ➡️</h2>
                        <p>Search through over 1 million past grant awards to find potential funders that may be a great fit for your organization</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={async () => {
                        setShowAddMoreGrantsModal(false);
                        const grantName = prompt("Enter a name for your new proposal:");
                        if (grantName) {
                            await createGrantProposalInFirestore(authUser, grantName, currentClientOrganization.id);
                            refreshGrantProposals();
                        }
                    }}>
                        <h2 className="text-xl font-bold"> 📝 Add a Known Grant ➡️</h2>
                        <p>Already have a grant in mind? Stay organized by adding it here</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMoreGrantsModal;
