import React, { useState } from 'react';

// Import Styling
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 
import './GrantDatabaseSearch.css'; 

// Import Components
import GrantSearchForm from './GrantSearchForm';

// Import internal functions
import { fetchGrantAwardsFromFirestore } from '../../utilityFunctions/firestoreUtilities';

// Import External Components
import { AgGridReact } from 'ag-grid-react'; 

function GrantDatabaseSearch({
    selectedOrganizationEin,
    setSelectedOpportunity,
    setSelectedOrganizationEin,
    setShowLoadingModal,
    setShowOpportunityAndFunderModal,    
}) {
    
    // State Variables

    const [formData, setFormData] = useState({
        grantSizeMin: '',
        grantSizeMax: '',
        awardDateStart: '',
        awardDateEnd: '',
        keywords: '',
        funderZip: '',
        funderState: '',
        recipientZip: '',
        recipientState: '',
        taxYr: ''
    });
    const [results, setResults] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [viewType, setViewType] = useState('individual'); 
    const [confirmedCriteria, setConfirmedCriteria] = useState({});
    
    // Component Methods
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        setShowLoadingModal(true)
        e.preventDefault();
        const fetchedGrantAwardsFromFirestore = await fetchGrantAwardsFromFirestore(formData);
        setResults(fetchedGrantAwardsFromFirestore);
        setShowForm(false);
        setShowLoadingModal(false)
        setConfirmedCriteria(formData); 
    };

    const groupByFunder = (results) => {
        const grouped = results.reduce((acc, result) => { 
            const fetchedFunderEin = result.FilerObject.FilerGeneralDetails.Ein;
            const fetchedFunderName = result.FilerObject.FilerGeneralDetails.BusinessName.Line1;
            const fetchedFunderCity = result.FilerObject.FilerGeneralDetails.Address.City
            const fetchedFunderState = result.FilerObject.FilerGeneralDetails.Address.State
            const fetchedFunderZip = result.FilerObject.FilerGeneralDetails.Address.Zip
            const fetchedFunderDesc = result.FilerObject.FilerGeneralDetails.Desc
            const fetchedFunderMission = result.FilerObject.FilerGeneralDetails.MissionDesc
            const fetchedFunderActivityOrMission = result.FilerObject.FilerGeneralDetails.ActivityOrMissionDesc        
            const fetchedFunderWebsite = result.FilerObject.FilerGeneralDetails.WebsiteAddressTxt

            if (!acc[fetchedFunderEin]) {
                acc[fetchedFunderEin] = {
                    funderEIN: fetchedFunderEin,
                    funderName: fetchedFunderName,
                    fetchedFunderCity: fetchedFunderCity,
                    fetchedFunderState: fetchedFunderState,
                    fetchedFunderZip: fetchedFunderZip,
                    fetchedFunderDesc: fetchedFunderDesc,
                    fetchedFunderMission: fetchedFunderMission,
                    fetchedFunderActivityOrMission: fetchedFunderActivityOrMission,
                    fetchedFunderWebsite: fetchedFunderWebsite,
                    cumulativeAmount: 0,
                    totalCount: 0
                };
            }
            acc[fetchedFunderEin].cumulativeAmount += parseFloat(result.AwardDetails.CashGrantAmt);
            acc[fetchedFunderEin].totalCount += 1;
            return acc;
        }, {});
        return Object.values(grouped);
    };

    const groupedResults = groupByFunder(results);

    const awardsGridColumnDefs = [
        { 
            headerName: "Tax Year", 
            field: "FilingDetails.TaxYr",
            width:100
        },
        { 
            headerName: "Funder Name", 
            field: "FilerObject.FilerGeneralDetails.BusinessName.Line1",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '',
            cellStyle: {fontWeight: 'bold', color: '#4338CA'}
        },
        { 
            headerName: "Recipient Name", 
            field: "RecipientDetails.RecipientBusinessName",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '',            
            cellStyle: {fontWeight: 'bold', color: '#4338CA'}
        },
        { 
            headerName: "Amount", 
            field: "AwardDetails.CashGrantAmt", 
            valueGetter: ({ data }) => data.AwardDetails.CashGrantAmt ? parseFloat(data.AwardDetails.CashGrantAmt) : 0, // Ensure sorting uses numeric values
            valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
            filter: "agNumberColumnFilter"
        },
        { 
            headerName: "Purpose", 
            field: "AwardDetails.PurposeOfGrantTxt",
            filter: "agTextColumnFilter",     
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''
        },
        { 
            headerName: "Recipient City", 
            field: "RecipientDetails.City",
            filter: "agTextColumnFilter",     
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''       
        },
        { 
            headerName: "Recipient State", 
            field: "RecipientDetails.State",
            filter: "agTextColumnFilter",            

        },
        { 
            headerName: "Recipient ZIP", 
            field: "RecipientDetails.ZIP",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Funder City", 
            field: "FilerObject.FilerGeneralDetails.Address.City",
            filter: "agTextColumnFilter",            
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''       

        },
        { 
            headerName: "Funder State", 
            field: "FilerObject.FilerGeneralDetails.Address.State",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Funder ZIP", 
            field: "FilerObject.FilerGeneralDetails.Address.Zip",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Funder Desc.", 
            field: "FilerObject.FilerGeneralDetails.Desc",
            filter: "agTextColumnFilter",            
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  

        },
        { 
            headerName: "Funder Mission", 
            field: "FilerObject.FilerGeneralDetails.MissionDesc",
            filter: "agTextColumnFilter",      
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  
      
        },
        { 
            headerName: "Funder Activity or Mission Desc.", 
            field: "FilerObject.FilerGeneralDetails.ActivityOrMissionDesc",
            filter: "agTextColumnFilter",          
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  
        },
        { 
            headerName: "Funder Website", 
            field: "FilerObject.FilerGeneralDetails.WebsiteAddressTxt",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.toLowerCase() : ''              
        },
    ];

    const groupedColDefs = [
        { 
            headerName: "Funder", 
            field: "funderName",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '',            
            cellStyle: {fontWeight: 'bold', color: '#4338CA'}
        },
        { 
            headerName: "Cumulative Amount", 
            field: "cumulativeAmount", 
            valueFormatter: ({ value }) => `$${parseInt(value).toLocaleString()}`,
            filter: "agNumberColumnFilter"
        },
        { 
            headerName: "Total Count", 
            field: "totalCount",
            valueFormatter: ({ value }) => `${parseInt(value).toLocaleString()}`,
            filter: "agNumberColumnFilter"
        },
        { 
            headerName: "Funder City", 
            field: "fetchedFunderCity",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''       

        },
        { 
            headerName: "Funder State", 
            field: "fetchedFunderState",
            filter: "agTextColumnFilter"
        },
        { 
            headerName: "Funder ZIP", 
            field: "fetchedFunderZip",
            filter: "agTextColumnFilter"
        },
        { 
            headerName: "Funder Desc.", 
            field: "fetchedFunderDesc",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  
        },
        { 
            headerName: "Funder Mission", 
            field: "fetchedFunderMission",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  
        },
        { 
            headerName: "Funder Activity or Mission Desc.", 
            field: "fetchedFunderActivityOrMission",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : ''  
        },
        { 
            headerName: "Funder Website", 
            field: "fetchedFunderWebsite",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.toLowerCase() : ''
        }
    ];

    const handleOrganizationCellClicked = (params) => {

        // Clear prior selected opportunity
        setSelectedOpportunity(null)
        setSelectedOrganizationEin(null)

        const { colDef, data } = params;
        let einOfClickedOrganization = '';

        if (colDef.field === 'FilerObject.FilerGeneralDetails.BusinessName.Line1') {
            einOfClickedOrganization = data.FilerObject.FilerGeneralDetails.Ein;
        } else if (colDef.field === 'RecipientDetails.RecipientBusinessName') {
            einOfClickedOrganization = data.AwardDetails.RecipientEIN;
        } else if (colDef.field === 'funderName') { 
            einOfClickedOrganization = data.funderEIN;
        }

        if (einOfClickedOrganization === selectedOrganizationEin) {
            setShowOpportunityAndFunderModal(true);
        }
        
        if (einOfClickedOrganization && einOfClickedOrganization !== selectedOrganizationEin) {
            setShowOpportunityAndFunderModal(false)
            setSelectedOrganizationEin(einOfClickedOrganization)
        }
    };

    return (
        <div className="text-left mt-4">
            
            {/* Criteria Header */}
            <div className='py-4'>
                <button onClick={() => setShowForm(prevShowForm => !prevShowForm)} className="bg-indigo-500 text-white px-4 py-2 rounded-lg">{results.length > 0 ? "Modify Search Criteria" : "Run a New Search"}</button>
                {Object.values(confirmedCriteria).some(value => value) && (
                    <ul className="mt-2">
                        {Object.entries(confirmedCriteria).map(([key, value]) => 
                            value && <li key={key} className="text-sm text-gray-700">{`${key}: ${value}`}</li>
                        )}
                    </ul>
                )}
            </div>
            
            {showForm && 
                <GrantSearchForm
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setShowForm={setShowForm}
                />
            }
            {/* Results Table */}
            {results.length > 0 && (
                <div>                                        
                   <div className="flex mt-4">
                       <button 
                           onClick={() => setViewType('individual')} 
                           className={`px-4 py-2 ${viewType === 'individual' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-black hover:bg-gray-300'}`}
                       >
                           View Individual Awards ({results.length.toLocaleString()})
                       </button>
                       <button 
                           onClick={() => setViewType('grouped')} 
                           className={`px-4 py-2 ${viewType === 'grouped' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-black hover:bg-gray-300'}`}
                       >
                           View Grouped by Funder ({groupedResults.length.toLocaleString()})
                       </button>
                   </div>
                   {viewType === 'individual' ? (
                       <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                           <AgGridReact
                               rowData={results}
                               columnDefs={awardsGridColumnDefs}
                               onCellClicked={handleOrganizationCellClicked}
                               enableCellTextSelection={true} 
                           />
                       </div>
                   ) : (
                        <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                           <AgGridReact
                               rowData={groupedResults}
                               columnDefs={groupedColDefs}
                               onCellClicked={handleOrganizationCellClicked}
                               enableCellTextSelection={true} 
                           />
                       </div>
                   )}
                   <p className='text-gray-500 italic mt-5'>Note: Max 10,000 results. Add additional criteria to filter reduce result count.</p>
                </div>
            )}
        </div>
    );
}

export default GrantDatabaseSearch;