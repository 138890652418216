import React from 'react';
import StateOptions from '../HelperComponents/StateOptions';

function GrantSearchForm({ 
    formData, 
    handleChange, 
    handleSubmit, 
    setShowForm 
}) {

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
            <div className="bg-white w-4/5 h-9/10 p-8 rounded-lg shadow-lg z-10 overflow-auto text-sm">
                <form onSubmit={handleSubmit}>
                    <p className='text-2xl mt-4 mb-4 text-center'>Advanced Search Criteria</p>
                    
                    <p className='font-bold mb-2 text-gray-500'>REQUIRED FIELDS</p>
                    <div className='bg-red-100 p-6 mb-6 rounded-lg'>
                        <div className="mb-2">
                            <label className="text-sm text-gray-700">Recipient State <span className="text-red-500">*</span></label>
                            <select name="recipientState" value={formData.recipientState} onChange={handleChange} className="advancedSearchInputField h-10" required>
                                <StateOptions />
                            </select>
                        </div>
                        <div className="mb-2">
                            <label className=" text-sm text-gray-700">Tax Year <span className="text-red-500">*</span></label>
                            <select name="taxYr" value={formData.taxYr} onChange={handleChange} className="advancedSearchInputField h-10" required>
                                <option value="">Select a year</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                            </select>
                        </div>
                    </div>
                    
                    <p className='font-bold mb-2 text-gray-500'>OPTIONAL FIELDS</p>
                    <div className='bg-gray-200 p-6 mb-4 rounded-lg flex flex-row justify-between'>                    
                        <div className="mb-4 w-1/2 pr-4">                            
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Grant Size (Min)</label>
                                <input type="number" name="grantSizeMin" value={formData.grantSizeMin} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Funder Zip</label>
                                <input type="text" name="funderZip" value={formData.funderZip} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Funder City</label>
                                <input type="text" name="funderCity" value={formData.funderCity} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Funder State</label>
                                <select name="funderState" value={formData.funderState} onChange={handleChange} className="advancedSearchInputField h-10">
                                    <StateOptions />
                                </select>
                            </div>                            
                        </div>
                        <div className="mb-2 w-1/2 pl-4">
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Grant Size (Max)</label>
                                <input type="number" name="grantSizeMax" value={formData.grantSizeMax} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                            
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Recipient City</label>
                                <input type="text" name="recipientCity" value={formData.recipientCity} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                            <div className="mb-2">
                                <label className="text-sm text-gray-700">Recipient Zip</label>
                                <input type="text" name="recipientZip" value={formData.recipientZip} onChange={handleChange} className="advancedSearchInputField" />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="bg-indigo-500 text-white px-4 py-2 mt-4 rounded-lg">Run Search</button>
                    <button onClick={() => setShowForm(false)} className="bg-gray-400 text-white px-4 py-2 ml-4 mt-4 rounded-lg">Cancel</button>
                </form>
            </div>
        </div>
    );
}

export default GrantSearchForm;