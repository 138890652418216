import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone'; // Import useDropzone
import { createVectorStore, createAssistantInOpenAi, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../../utilityFunctions/openAiUtilities';
import { saveAssistantToFirestore, createClientOrganizationInFirestore, createProgramInFirestore, createGoalInFirestore } from '../../utilityFunctions/firestoreUtilities';
import { generateUniqueFileId } from '../../utilityFunctions/uxUtilities';
import { AuthContext } from '../../AuthContext';




function CreateAssistantForm({ 
    fetchAssistantFiles,
    fetchClientOrganizationsBelongedTo,
    fetchProgramsForOrganization,
    setAssistantId, 
    setassistantVectorStoreId, 
    setShowScrapingLoadingModal 
}) {

    const { authUser } = useContext(AuthContext);
    const SCRAPINGANT_API_KEY = '88b4850dd26f45cf936f63846d3db5e2'; 
    let createdVectorStore;
    // const [files, setFiles] = useState([]); // State to manage files

    // // Add a function to handle file uploads
    // const onDrop = (acceptedFiles) => {
    //     setFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => Object.assign(file, {
    //         preview: URL.createObjectURL(file)
    //     }))]);
    // };

    // const removeFile = (fileName) => {
    //     setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    // };

    // const { getRootProps, getInputProps } = useDropzone({ onDrop });

    async function extractWebsiteDetails(url) {
        const apiUrl = `https://api.scrapingant.com/v2/extract?url=${encodeURIComponent(url)}&extract_properties=overview,mission,populationsServed,areasServed&x-api-key=${SCRAPINGANT_API_KEY}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error('Failed to fetch website details');
        }
        return response.json();
    }
    
    const handleCreateAssistantButtonPressed = async (event) => {
        event.preventDefault();
        const websiteLink = event.target.websiteLink.value;
        const organizationName = event.target.organizationName.value;
        const programName = event.target.programName.value;
        const geographicAreas = event.target.geographicAreas.value;
        const populationServed = event.target.populationServed.value;
        const servicesProvided = event.target.servicesProvided.value;
        const happyFunding = event.target.happyFunding.value; // Use happyFunding instead of goalAmount

        if (!websiteLink || !organizationName) {
            alert('Both fields are required.');
            return;
        }

        try {
            console.log("Fetching website details with ScrapingAnt")
            setShowScrapingLoadingModal(true);

            createdVectorStore = await createVectorStore();
            const myAssistant = await createAssistantInOpenAi(organizationName, createdVectorStore.id);            
            const createdClientOrganizationRef = await createClientOrganizationInFirestore(organizationName, authUser.uid);
            const createdClientOrganizationId = createdClientOrganizationRef.id; 
            await saveAssistantToFirestore(myAssistant, createdVectorStore.id, websiteLink, createdClientOrganizationId);

            // Create goal in Firestore
            await createGoalInFirestore(happyFunding, createdClientOrganizationId);

            try {
                const createProgramWithTimeout = new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(new Error("Timeout: Failed to create program in Firestore within 60 seconds"));
                    }, 60000);

                    createProgramInFirestore({
                        overview: servicesProvided,
                        mission: null, // or any other field you want to map
                        populationsServed: populationServed,
                        areasServed: geographicAreas
                    }, authUser.uid, createdClientOrganizationId, programName)
                        .then((result) => {
                            clearTimeout(timeout);
                            resolve(result);
                        })
                        .catch((error) => {
                            clearTimeout(timeout);
                            reject(error);
                        });
                });

                await createProgramWithTimeout;
            } catch (error) {
                console.error("Failed to create program in Firestore:", error);
            }

            setShowScrapingLoadingModal(false);
            setAssistantId(myAssistant.id);
            setassistantVectorStoreId(createdVectorStore.id);
            fetchClientOrganizationsBelongedTo(authUser.uid);
            fetchProgramsForOrganization(createdClientOrganizationId);
            
        } catch (error) {
            console.log("Error, please contact support:", error);        
        }
    };

    return (
        <div className="fixed inset-0 flex justify-start items-start w-full h-full p-12 md:p-48 text-left bg-gray-100 bg-opacity-100 overflow-auto md:overflow-hidden">
            <form onSubmit={handleCreateAssistantButtonPressed} className="flex flex-wrap">
                <h1 className="text-3xl font-bold w-full">Welcome to GrantMagic!</h1>
                <p className="text-md mt-3 mb-10 text-gray-600 w-full">Answer eight questions about your nonprofit. Daisy will learn from your responses to help discover and edit grants for your nonprofit.</p>
                
                <div className="w-full md:w-1/2 pr-4">
                    <div>
                        <label htmlFor="organizationName" className="block mb-2 text-sm font-medium text-gray-900">What is the name of your nonprofit?</label>                        
                        <input type="text" id="organizationName" name="organizationName" placeholder='Public Housing Community Fund' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div>
                        <label htmlFor="websiteLink" className="block mb-2 mt-10 text-sm font-medium text-gray-900">What is your nonprofit's website?</label>
                        <input 
                            type="url" 
                            id="websiteLink" 
                            name="websiteLink" 
                            placeholder='https://example.org' 
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            required 
                            pattern="https?://.+"
                        />
                        <small className="text-gray-600">URL must start with http:// or https://</small>
                    </div>
                    <div>
                        <label htmlFor="grantFunding" className="block mb-2 mt-10 text-sm font-medium text-gray-900">How much grant funding ($) have you won over the past 12 months?</label>
                        <input type="number" id="grantFunding" name="grantFunding" placeholder='0' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div>
                        <label htmlFor="happyFunding" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Complete the following sentence: "If GrantMagic helped me raise $_______ in grant funding this year, I would be very happy."</label>
                        <input type="number" id="happyFunding" name="happyFunding" placeholder='0' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                </div>

                <div className="w-full md:w-1/2 md:pl-4 pt-10 md:pt-0">
                    <div>
                        <label htmlFor="programName" className="block mb-2 text-sm font-medium text-gray-900">What is the name of the program you want grant funding for?</label>
                        <input type="text" id="programName" name="programName" placeholder='Program Name' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div>
                        <label htmlFor="geographicAreas" className="block mb-2 mt-10 text-sm font-medium text-gray-900">What geographic areas does this program serve?</label>
                        <input type="text" id="geographicAreas" name="geographicAreas" placeholder='Geographic Areas' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div>
                        <label htmlFor="populationServed" className="block mb-2 mt-10 text-sm font-medium text-gray-900">What population / community does this program serve?</label>
                        <input type="text" id="populationServed" name="populationServed" placeholder='Population / Community' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div>
                        <label htmlFor="servicesProvided" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Finally, what services / work does this program provide?</label>
                        <textarea id="servicesProvided" name="servicesProvided" placeholder='Services / Work' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></textarea>
                    </div>
                </div>

                <button
                    type="submit"
                    className="mt-12 bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full"
                >
                    Create
                </button>
            </form>
        </div>
    );
}

export default CreateAssistantForm;
