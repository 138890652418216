import React from 'react';
import openai from './config/openaiConfig';
import { useState, useEffect } from 'react';
import { fetchMemberEmails, handleAddTeammateToFirestore, handleAddLicenseKeyClicked } from './utilityFunctions/firestoreUtilities'; // Import the function
// import { db } from '../config/firebaseConfig'; // Import your Firebase config



function AccountSettings({
    assistantId,
    assistantVectorStoreId,
    currentClientOrganization,
    // fetchClientOrganizationsBelongedTo,
    membershipDetails
}) {

    const [assistant, setAssistant] = useState(null);
    const [teammateEmails, setTeammateEmails] = useState([]);

    const fetchAssistant = async () => {
        const myAssistant = await openai.beta.assistants.retrieve(assistantId);
        setAssistant(myAssistant);
    };

    const handleAddTeammateClicked = async () => {
        await handleAddTeammateToFirestore(currentClientOrganization)        
        // fetchClientOrganizationsBelongedTo()
        const fetchedEmails = await fetchMemberEmails(currentClientOrganization);
        setTeammateEmails(fetchedEmails);
    }

    const fetchEmails = async () => {
        if (currentClientOrganization) {
            const fetchedEmails = await fetchMemberEmails(currentClientOrganization);
            setTeammateEmails(fetchedEmails);
        }
    };



    useEffect(() => {
        fetchAssistant();
    }, [assistantId]);

    useEffect(() => {
        fetchEmails();
    }, [currentClientOrganization]);

    return (
        <div className='flex flex-col justify-center p-12'>
            <p className="text-3xl mt-10 font-bold text-left">Account Settings</p>                 
            <div className='text-left mt-10'>
                {/* Plan */}
                <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>PLAN TYPE</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2'>
                        {membershipDetails 
                            ? 
                                <div>
                                    <p>Current Plan: <span className="text-indigo-500 font-bold">Premium</span></p> 
                                    <p>The Premium plan includes unlimited access to our Grant Database and Grant Discovery features.</p>
                                    <p className='text-gray-500 italic text-sm mt-6'>To make changes to your subscription, contact Support.</p>
                                </div>                    
                            :  
                                <div>
                                    <p>Current Plan: <span className="text-gray-500 font-bold">Free</span></p>                                     
                                    <p>To get access to our grant database functionality, <a className='text-indigo-500 font-bold' href="https://satodadj.gumroad.com/l/rlqzat" target="_blank" rel="noopener noreferrer">upgrade here</a>, then add your license key using the button below.</p>

                                    <button 
                                        className="bg-indigo-500 text-white py-2 px-4 rounded"
                                        onClick={() => handleAddLicenseKeyClicked(currentClientOrganization.id)}
                                    >
                                        Add License Key
                                    </button>                                    
                                </div>
                        }
                        {/* <div className='mt-6 text-gray-500'>
                            Want access to our Grant Database and Grant Discovery features, but don't have enough budget? No worries, we offer two ways to get free access to Grant Magic:
                            <ul className="list-disc ml-5 mt-2">
                                <li>
                                    <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Referral-Program-ce8129a79f1445e0a32f25867ac94b01?pvs=4" className="text-indigo-500 underline" target="_blank" rel="noopener noreferrer">Refer a friend</a>
                                </li>
                                <li>
                                    <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Case-Study-Program-c9964ca155424795a416baf59b893b5c?pvs=4" className="text-indigo-500 underline" target="_blank" rel="noopener noreferrer">Join our case study program</a>
                                </li>
                            </ul>
                        </div>                 */}
                    </div>
                </div>
                
                {/* Assistant */}
                {/* <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>ASSISTANT SETTINGS</p>
                    <div className='bg-gray-200 rounded text-left p-3 text-sm my-2'>
                        {assistant && (
                            <div className="text-left w-full max-w-4xl">
                                
                                Heading Page
                                <div className=''>
                                    <div><strong>Name:</strong> {assistant.name}</div>
                                    <div><strong>Created At:</strong> {new Date(assistant.created_at * 1000).toLocaleString()}</div>
                                    <div><strong>Instructions:</strong> {assistant.instructions}</div>
                                </div>                    
                                
                                Debugging Section
                                <div className='mt-6 italic text-gray-500 text-sm'>
                                    <p>For debugging only:</p>
                                    <div>ID: {assistant.id}</div>
                                    <div>Model: {assistant.model}</div>
                                    <div>Temperature: {assistant.temperature}</div>
                                    <div>Response Format: {assistant.response_format}</div>
                                    <div>Tools: {assistant.tools.map(tool => tool.type).join(', ')}</div>
                                    <div>Top P: {assistant.top_p}</div>
                                    <div>Vector Store ID: {assistantVectorStoreId}</div>                        
                                </div>
                            </div>
                        )}       
                    </div>
                </div> */}
                
                {/* Sharing */}
                <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>SHARING</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2'>
                        {currentClientOrganization && (
                            <div>
                                <p>Current Organization: <span className="text-indigo-500 font-bold">{currentClientOrganization.name}</span></p> 
                                <p>Organization ID: <span className="text-indigo-500 font-bold">{currentClientOrganization.id}</span></p> 
                                <p className='mt-4'>Team Members</p>
                                <ul>
                                    {teammateEmails.map((email, index) => (
                                        <li className='ml-4' key={index}>- {email}</li>
                                    ))}
                                </ul>                                
                                
                                <button 
                                    className='bg-indigo-500 p-2 text-white rounded-lg mt-4'
                                    onClick={handleAddTeammateClicked}
                                >
                                    Add Teammate
                                </button>
                                <p className='mt-6 italic text-gray-500 text-sm'>Contact support if any assistance needed in managing team member access.</p>
                            </div>                                                
                            
                        )}       
                        
                    </div>                    
                </div>

            </div>
        </div>                    
  );
}

export default AccountSettings;