import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

// Import functions
import { cleanText } from '../utilityFunctions/uxUtilities';
import { fetchFilesFromOpenAI, getAnswerFromOpenAI } from '../utilityFunctions/openAiUtilities';
import { fetchFilesMetadataFromFirestore, fetchClientProgramsFromFirestore, fetchAssistantDetailsFromFirestoreForOrganization, fetchClientOrganizationsFromFirestore, fetchGrantProposalsFromFirestoreForOrganization, createGrantProposalInFirestore, createGrantProposalComponentInFirestore, markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore, fetchAssistantDetailsFromFirestore, fetchMembershipDetailsFromFirestore, fetchOrganizationDetailsFromFirestore, fetchMembershipDetailsFromFirestoreForOrganization } from '../utilityFunctions/firestoreUtilities';

// Import configs
import { AuthContext } from '../AuthContext';
import openai from '../config/openaiConfig';
import { auth } from '../config/firebaseConfig'; 

// Import components
import OpportunityAndFunderModal from './GrantDiscoveryComponents/OpportunityAndFunderModal';
import ProgramDashboard from './GrantDiscoveryComponents/ProgramDashboard';
import AssistantPage from './AssistantComponents/AssistantPage';
import AssistantSettingsPage from './AssistantComponents/AssistantSettingsPage'
import CreateAssistantForm from './AssistantComponents/CreateAssistantForm';
import LoadingModal from './HelperComponents/LoadingModal';
import ManageProposalModal from './GrantWritingComponents/ManageProposalModal';
import PaywallComponent from './HelperComponents/PaywallComponent';
import MagicFirstDraftModal from './GrantWritingComponents/MagicFirstDraftModal';
import SideNavBar from './SideNavBar';
import LoggedOutWelcome from './AuthComponents/LoggedOutWelcome';
import HomeChat from './HomeChat';
import AwardsDatabase from './GrantDiscoveryComponents/AwardsDatabase';
import OpportunitiesDatabase from './GrantDiscoveryComponents/OpportunitiesDatabase';
import ProposalsDashboard from './GrantWritingComponents/ProposalsDashboard';
import ProposalEditor from './GrantWritingComponents/ProposalEditor';
import KnowledgeBaseModal from './AssistantComponents/KnowledgeBaseModal';
import MagicFirstDraftLoadingModal from './GrantWritingComponents/MagicFirstDraftLoadingModal';
import ManageMembership from '../ManageMembership';
import GetFreeGrantMagic from '../GetFreeGrantMagic';
import ScrapingLoadingModal from './HelperComponents/ScrapingLoadingModal';
// import OrganizationDetails from './AssistantComponents/OrganizationDetails';
import ClientOrganizationsPickerModal from './ClientOrganizationsPickerModal';
import CreateProgramModal from './GrantDiscoveryComponents/CreateProgramModal';
import AccountSettings from '../AccountSettings';
import UserSimulator from '../UserSimulator';
import AddMoreGrantsModal from './GrantDiscoveryComponents/AddMoreGrantsModal';
import MobileWelcome from './AuthComponents/MobileWelcome';

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
  // return true
};

function Home() {

  const { authUser } = useContext(AuthContext);  

  // Top level state variables
  const [assistantId, setAssistantId] = useState(null);
  const [assistantVectorStoreId, setassistantVectorStoreId] = useState(null);
  const [currentView, setCurrentView] = useState('proposalsDashboard'); 
  const [assistantFiles, setAssistantFiles] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [clientOrganizationsBelongedTo, setClientOrganizationsBelongedTo] = useState([]);
  const [currentClientOrganization, setCurrentClientOrganization] = useState(null);
  const [organizationPrograms, setOrganizationPrograms] = useState([]);

  // Grant discovery state variables
  const [selectedOpportunity, setSelectedOpportunity] = useState()
  const [selectedOrganizationEin, setSelectedOrganizationEin] = useState(); 
  const [currentProgram, setCurrentProgram] = useState(null)
  const [programToModify, setProgramToModify] = useState(null)

  // Grant writing state variables
  const [grantProposals, setGrantProposals] = useState([]);
  const [currentProposal, setCurrentProposal] = useState();
  const [currentProposalToManage, setCurrentProposalToManage] = useState();
  
    // Modal state variables
  const [showKnowledgeBaseModal, setShowKnowledgeBaseModal] = useState(false);   
  const [showMagicFirstDraftLoadingModal, setShowMagicFirstDraftLoadingModal] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showOpportunityAndFunderModal, setShowOpportunityAndFunderModal] = useState(false)
  const [showManageProposalModal, setShowManageProposalModal] = useState(false)  
  const [showMagicFirstDraftModal, setShowMagicFirstDraftModal] = useState(false)
  const [showScrapingLoadingModal, setShowScrapingLoadingModal] = useState(false)
  const [showPaywallModal, setShowPaywallModal] = useState(false)
  const [showClientOrganizationsPickerModal, setShowClientOrganizationsPickerModal] = useState(false)
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false)
  const [showAddMoreGrantsModal, setShowAddMoreGrantsModal] = useState(false)
  const [showCreateAssistantForm, setShowCreateAssistantForm] = useState(false);

  // useEffect methods

  // When authUser changes, get assistantId and plan details
  useEffect(() => {        
    if (authUser) { 
        console.log("authUser changed. Fetching assistant and membership details");
        // fetchAssistantDetails();        
        // fetchMembershipDetails(); // Eventualy, move this down as well
        // fetchOrganizationDetails();
        if (authUser.uid) {
            fetchClientOrganizationsBelongedTo(authUser.uid);
        }
    } else {
        console.log("authUser not set. Skipping fetch for Assistant ID");
    }
  }, [authUser]); 

  useEffect(() => {
    console.log("Client organizations belonged to:", clientOrganizationsBelongedTo);
    setCurrentClientOrganization(clientOrganizationsBelongedTo[0]) // future: can do something nice here like take the last action made to choose the default
  }, [clientOrganizationsBelongedTo]);


  useEffect(() => {
    if (currentClientOrganization) {
      console.log("Current client organization changed to:", currentClientOrganization);
      fetchProgramsForOrganization(currentClientOrganization.id);
      fetchAssistantDetailsForOrganization(currentClientOrganization.id);
      fetchMembershipDetails()
      refreshGrantProposals()
    }
  }, [currentClientOrganization]);

  // When assistantId changes, fetch its vector store files
  useEffect(() => {        
    if (assistantId !== null) {
        console.log("Assistant ID changed. Fetching assistant vector store files.")
        fetchAssistantFiles();
    }
  }, [assistantId]); // Dependency array includes assistantId to ensure this effect only runs when assistantId is not null

    

  // If selectedOpportunity changes, also change selectedOrganizationEin
  useEffect(() => {
    if (selectedOpportunity) {
      setSelectedOrganizationEin(selectedOpportunity.ein)
    }
  }, [selectedOpportunity]);

  useEffect(() => {
    if (currentProgram) {
      console.log("currentProgramChanged")
      // setCurrentView()
    }
  }, [currentProgram]);

  useEffect(() => {
    if (selectedOrganizationEin) {
      console.log("selectedOrganizationEin set, showing OAF modal.")
      setShowOpportunityAndFunderModal(true);
    }
  }, [selectedOrganizationEin]);

  useEffect(() => {
    if (currentProposal) {
      setCurrentView('proposalEditor');
    }
  }, [currentProposal]);

  useEffect(() => {
    if (authUser && !assistantId) {
      const timer = setTimeout(() => {
        setShowCreateAssistantForm(true);
      }, 1000); // 2000 milliseconds = 2 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when dependencies change
    } else {
      setShowCreateAssistantForm(false);
    }
  }, [authUser, assistantId]);

  // Other Functions
  
  
  const fetchProgramsForOrganization = async (organizationId) => {
    const clientPrograms = await fetchClientProgramsFromFirestore(organizationId);
    setOrganizationPrograms(clientPrograms);
    
    return clientPrograms
  };
  
  const generateMagicFirstDraft = async (applicationRequirements, grantName) => {
    
    setShowMagicFirstDraftLoadingModal(true)
    
    const extractedQuestions = await extractQuestionsFromTextWithOpenAi(applicationRequirements);
    
    let newGrantProposalId; 

    try {
        newGrantProposalId = await createGrantProposalInFirestore(authUser, grantName, currentClientOrganization.id); 
    } catch (error) {
        console.error("Error creating grant proposal:", error);
    }

    if (extractedQuestions && Array.isArray(extractedQuestions.questions)) {
        for (const questionObj of extractedQuestions.questions) {
          const componentText = questionObj.question;
          const responseText = await getAnswerFromOpenAI(questionObj.question, assistantFiles, assistantId);
          createGrantProposalComponentInFirestore(newGrantProposalId, componentText, responseText);
        }
    } else {
        alert("Extracted questions is not in the expected format:", extractedQuestions);
    }

    // const fetchedGrantProposals = await fetchGrantProposalsFromFirestore(authUser);
    const fetchedGrantProposals = await fetchGrantProposalsFromFirestoreForOrganization(currentClientOrganization.id);
    const newGrantProposal = fetchedGrantProposals.find(proposal => proposal.id === newGrantProposalId);
    
    setCurrentProposal(newGrantProposal)
    setShowOpportunityAndFunderModal(false)
    setShowMagicFirstDraftLoadingModal(false)
    setCurrentView('proposalEditor')

  };  

  const refreshGrantProposals = async () => {
    if (!currentClientOrganization || !currentClientOrganization.id) {
        console.error("Current client organization is not set.");
        return;
    }
    const fetchedGrantProposals = await fetchGrantProposalsFromFirestoreForOrganization(currentClientOrganization.id);
    console.log("fetchedGrantProposals: ", fetchedGrantProposals);
    setGrantProposals(fetchedGrantProposals);
};
  
  // Function to extract questions from text
    const extractQuestionsFromTextWithOpenAi = async (text) => {
      const cleanedText = cleanText(text);
      console.log("Sending request to OpenAI to extract questions...", cleanedText);
      let response;
      try {
          response = await openai.chat.completions.create({
              model: "gpt-4o",
              response_format: { type: "json_object" },
              messages: [
                  { role: "system", content: "You are an assistant trained to extract a list of application questions / prompts from raw, unstructured text." },
                  { role: "user", content: `Extract all application questions / prompts from the following text. Format each question as a JSON object with the key 'question'. Return a JSON array of these objects. Text: ${cleanedText}` },
                  { role: "user", content: `Example output: [{"question": "What is your name?"}, {"question": "Describe your project."}]` }
              ],
              temperature: 0,
              n: 1,
          });
          console.log("Response received from OpenAI.", response);
      } catch (error) {
          console.error("Failed to get response from OpenAI:", error);
          throw error;
      }

      let questions = response.choices[0].message.content;
      console.log("Extracted questions content:", questions);

      // Remove code block markers if present
      questions = questions.replace(/```json|```/g, '');
      
      let questionsArray;
      try {
          questionsArray = JSON.parse(questions);
          console.log("Parsed questions into JSON array.");
      } catch (parseError) {
          console.error("Error parsing questions JSON:", parseError);
          throw parseError;
      }

      return questionsArray;
  };
  

  // Main function to fetch clientOrganizations which user belongs to
  const fetchClientOrganizationsBelongedTo = async (uid) => {
    console.log("fetchClientOrganizationsBelongedTo starting");
    // if (!auth.currentUser || !auth.currentUser.uid) {
    //     console.error("No current user or user ID found.");
    //     return;
    // }

    try {
      // const clientOrganizations = await fetchClientOrganizationsFromFirestore(auth.currentUser.uid);  
      const clientOrganizations = await fetchClientOrganizationsFromFirestore(uid);
        setClientOrganizationsBelongedTo(clientOrganizations)
    } catch (error) {
        console.error("Error fetching client organizations: ", error);
    }
  };

  // Main function to fetch plan details
  const fetchMembershipDetails = async () => {
    console.log("fetchMembershipDetails starting");
    if (!auth.currentUser || !currentClientOrganization) {
        console.error("No current user / current clientOrg found.");
        return;
    }

    try {
        // const membershipDetails = await fetchMembershipDetailsFromFirestore(auth.currentUser.email);
        const membershipDetails = await fetchMembershipDetailsFromFirestoreForOrganization(currentClientOrganization.id);
        setMembershipDetails(membershipDetails)
    } catch (error) {
        console.error("Error fetching membership details: ", error);
    }
  };

  // Main function to fetch assistant details for current user
  // const fetchOrganizationDetails = async () => {
  //   console.log("fetchOrganizationDetails starting");
  //   if (!auth.currentUser || !auth.currentUser.uid) {
  //       console.error("No current user or user ID found.");
  //       return;
  //   }
  //   try {
  //       const organizationDetails = await fetchOrganizationDetailsFromFirestore(auth.currentUser.uid);
  //       setOrganizationDetails(organizationDetails)
  //   } catch (error) {
  //       console.error("Error fetching organizationDetails: ", error);
  //   }
  // };
  
  // Main function to fetch assistant details for current user
  // const fetchAssistantDetails = async () => {
  //   console.log("fetchAssistantDetails starting");
  //   if (!auth.currentUser || !auth.currentUser.uid) {
  //       console.error("No current user or user ID found.");
  //       return;
  //   }

  //   try {
  //       const assistantDetails = await fetchAssistantDetailsFromFirestore(auth.currentUser.uid);

  //       if (assistantDetails) {
  //           const { assistantId, assistantName, assistantVsId } = assistantDetails;
  //           console.log(`assistantId ${assistantId} found, setting statevar now`);
  //           setAssistantId(assistantId);
                
  //           console.log(`assistantVsId ${assistantVsId} found, setting statevar now`);
  //           setassistantVectorStoreId(assistantVsId);                

  //       } else {
  //           console.log("No assistant details found.");
  //       }
  //   } catch (error) {
  //       console.error("Error fetching assistant details: ", error);
  //   }
  // };


  const fetchAssistantDetailsForOrganization = async (clientOrganizationId) => {
    console.log("fetchAssistantDetailsForOrganization starting for COI: ", clientOrganizationId);
    if (!currentClientOrganization) {
        console.error("currentClientOrganization not found.");
        return;
    }

    try {
        const assistantDetails = await fetchAssistantDetailsFromFirestoreForOrganization(clientOrganizationId);

        if (assistantDetails) {
            const { assistantId, assistantName, assistantVsId } = assistantDetails;
            console.log(`assistantId ${assistantId} found, setting statevar now`);
            setAssistantId(assistantId);
                
            console.log(`assistantVsId ${assistantVsId} found, setting statevar now`);
            setassistantVectorStoreId(assistantVsId);                

        } else {
            console.log("No assistant details found.");
            setAssistantId(null)
            setassistantVectorStoreId(null);                
            setAssistantFiles([])
        }
    } catch (error) {
        console.error("Error fetching assistant details: ", error);
    }
  };
  
  
  // Main function to fetch assistant files
  async function fetchAssistantFiles() {
    if (!assistantVectorStoreId) {
        console.log("assistantVectorStoreId is null, skipping fetch.");
        return;
    }

    try {
        const vectorStoreFiles = await fetchFilesFromOpenAI(assistantVectorStoreId);
        const vectorStoreFilesWithMetadata = await fetchFilesMetadataFromFirestore(vectorStoreFiles);            
        setAssistantFiles(vectorStoreFilesWithMetadata);
        console.log("Successfully fetched vector store files with metadata:", vectorStoreFilesWithMetadata);
    } catch (error) {
        console.error("Error fetching vector store files: ", error);
    }
  }



    const renderView = () => {
      if (isMobileDevice()) {
        return <MobileWelcome />;
      }

      switch (currentView) {
        case 'homeChat':
          return <HomeChat 
            organizationPrograms={organizationPrograms}  
            setCurrentView={setCurrentView}
            setCurrentProgram={setCurrentProgram}
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
          />;
        case 'manageMembership':
          return <ManageMembership                
            membershipDetails={membershipDetails}
          />;
        case 'accountSettings':
          return <AccountSettings                
            assistantId={assistantId}
            assistantVectorStoreId={assistantVectorStoreId}
            currentClientOrganization={currentClientOrganization}
            // fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
            membershipDetails={membershipDetails}
          />;
        // case 'organizationDetails':
        //   return <OrganizationDetails                
        //     organizationDetails={organizationDetails}
        //   />;
        case 'getFreeGrantMagic':
          return <GetFreeGrantMagic                
          />;
        case 'awardsDatabase':
        return <AwardsDatabase 
          membershipDetails={membershipDetails}
          setShowLoadingModal={setShowLoadingModal}
          setSelectedOrganizationEin={setSelectedOrganizationEin}
          selectedOrganizationEin={selectedOrganizationEin}
          setSelectedOpportunity={setSelectedOpportunity}
          setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
          />;
        // case 'opportunitiesDatabase':
        //   return <OpportunitiesDatabase 
        //     membershipDetails={membershipDetails}
        //     setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
        //     setSelectedOpportunity={setSelectedOpportunity}
        //   />;
        case 'programDashboard':
          return <ProgramDashboard 
            currentProgram={currentProgram}  
            membershipDetails={membershipDetails}
            organizationPrograms={organizationPrograms}
            setCurrentProgram={setCurrentProgram}
            setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}
            setShowPaywallModal={setShowPaywallModal}              
            setProgramToModify={setProgramToModify}
            setShowCreateProgramModal={setShowCreateProgramModal}                      
            setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
            setSelectedOpportunity={setSelectedOpportunity}                                  
            authUser={authUser}
            currentClientOrganization={currentClientOrganization}
          />;
        case 'assistantPage':
          return <AssistantPage 
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
            assistantFiles={assistantFiles}
            assistantId={assistantId}
            currentClientOrganization={currentClientOrganization}
            setAssistantFiles={setAssistantFiles}
            assistantVectorStoreId={assistantVectorStoreId}
            fetchAssistantFiles={fetchAssistantFiles}
            setShowLoadingModal={setShowLoadingModal}                     
          />;
        case 'assistantSettings':
          return <AssistantSettingsPage
            assistantId={assistantId}
            assistantVectorStoreId={assistantVectorStoreId}
          />
        
        case 'proposalEditor':
          return <ProposalEditor 
            currentProposal={currentProposal}
            setCurrentProposal={setCurrentProposal}
            setCurrentView={setCurrentView}
            assistantFiles={assistantFiles}            
            setShowLoadingModal={setShowLoadingModal}
            assistantId={assistantId}                            
            // fetchAssistantFiles={fetchAssistantFiles}              
          />;                    
        case 'proposalsDashboard':
          return <ProposalsDashboard 
            authUser={authUser}
            setCurrentProposal={setCurrentProposal}
            currentProposal={currentProposal}
            membershipDetails={membershipDetails}
            currentClientOrganization={currentClientOrganization}
            grantProposals={grantProposals}
            refreshGrantProposals={refreshGrantProposals}
            setCurrentProposalToManage={setCurrentProposalToManage}
            setShowAddMoreGrantsModal={setShowAddMoreGrantsModal} // Ensure this is passed
            setShowMagicFirstDraftModal={setShowMagicFirstDraftModal}
            setShowManageProposalModal={setShowManageProposalModal}
            setShowPaywallModal={setShowPaywallModal}
          />;
        default:
          return <HomeChat 
            setCurrentView={setCurrentView}
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
          />;
      }
  };

    return (
      <div className="flex" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        
        {/* Modals */}
        <div>
          
          {/* {true && (
            <UserSimulator 
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}              
            />
          )} */}

          {showLoadingModal && (
            <LoadingModal              
            />
          )}

          {showPaywallModal && (
            <PaywallComponent
              onClose={() => setShowPaywallModal(false)}           
            />
          )}


          {showScrapingLoadingModal && (
            <ScrapingLoadingModal              
            />
          )}

          {showAddMoreGrantsModal && (
            <AddMoreGrantsModal
              setCurrentView={setCurrentView}
              setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}
              authUser={authUser}
              currentClientOrganization={currentClientOrganization}
              refreshGrantProposals={refreshGrantProposals}
            ></AddMoreGrantsModal>
          )}


          {showCreateProgramModal && assistantId && (
            <CreateProgramModal         
              currentClientOrganization={currentClientOrganization} 
              existingProgram={programToModify}
              fetchProgramsForOrganization={fetchProgramsForOrganization}
              setCurrentProgram={setCurrentProgram}
              setShowCreateProgramModal={setShowCreateProgramModal}                            
            />
          )}


          {showClientOrganizationsPickerModal && (
            <ClientOrganizationsPickerModal         
              clientOrganizationsBelongedTo={clientOrganizationsBelongedTo}
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
              setCurrentClientOrganization={setCurrentClientOrganization}
              setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}
              membershipDetails={membershipDetails}
              setShowPaywallModal={setShowPaywallModal}
            />
          )}


          
          {showManageProposalModal && (
            <ManageProposalModal
              currentProposalToManage={currentProposalToManage}  
              refreshGrantProposals={refreshGrantProposals}
              setShowManageProposalModal={setShowManageProposalModal}            
            />
          )}

          {showMagicFirstDraftLoadingModal && (
            <MagicFirstDraftLoadingModal
            ></MagicFirstDraftLoadingModal>

          )}

          {showMagicFirstDraftModal && (
            <MagicFirstDraftModal
              generateMagicFirstDraft={generateMagicFirstDraft}
              refreshGrantProposals={refreshGrantProposals}
              setShowMagicFirstDraftModal={setShowMagicFirstDraftModal}              
            />
          )}

          {showOpportunityAndFunderModal && (
            <OpportunityAndFunderModal
              generateMagicFirstDraft={generateMagicFirstDraft}
              membershipDetails={membershipDetails}
              selectedOpportunity={selectedOpportunity}
              selectedOrganizationEin={selectedOrganizationEin}
              setSelectedOpportunity={setSelectedOpportunity}
              setShowLoadingModal={setShowLoadingModal}
              setSelectedOrganizationEin={setSelectedOrganizationEin}
              setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
              setShowPaywallModal={setShowPaywallModal}
            />
          )}

          {showKnowledgeBaseModal && assistantId && (
            <KnowledgeBaseModal 
              setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
              assistantFiles={assistantFiles}
              setAssistantFiles={setAssistantFiles}
              assistantVectorStoreId={assistantVectorStoreId}
              fetchAssistantFiles={fetchAssistantFiles}
              setShowLoadingModal={setShowLoadingModal}
            />
          )}     

        </div>           
        <SideNavBar 
            currentView={currentView}
            currentClientOrganization={currentClientOrganization}
            organizationPrograms={organizationPrograms}
            setCurrentProgram={setCurrentProgram}
            setCurrentView={setCurrentView}                         
            setProgramToModify={setProgramToModify}
            setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}          
            setShowCreateProgramModal={setShowCreateProgramModal}
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}                         
        />
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {authUser ? (
            assistantId ? (
              <div>
                {renderView()}
              </div>
            ) : (
              showCreateAssistantForm && (
                <CreateAssistantForm 
                  fetchAssistantFiles={fetchAssistantFiles}   
                  fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
                  fetchProgramsForOrganization={fetchProgramsForOrganization}                             
                  setAssistantId={setAssistantId}
                  setassistantVectorStoreId={setassistantVectorStoreId}      
                  setShowScrapingLoadingModal={setShowScrapingLoadingModal}
                />
              )
            )
          ) : (
            <LoggedOutWelcome></LoggedOutWelcome>
          )}
        </div>
      </div>
    );
}

export default Home;