import React, { useState, useEffect, useCallback } from 'react';
import { doc, updateDoc, setDoc, Timestamp } from "firebase/firestore";
import db from '../../config/firebaseConfig';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ComponentVersionHistoryModal from './ComponentVersionHistoryModal';
import history from '../../assets/history-white.png';
import magicWand from '../../assets/magic-wand.png';
import trashWhite from '../../assets/trash-white.png';
import AiComponentEditorModal from './AiComponentEditorModal';

const ProposalTextComponent = ({ 
    component, 
    assistantFiles, 
    handleProposalComponentDeletion,     
    setShowLoadingModal,
    assistantId
 }) => { 

    const [unsavedChangesPresent, setUnsavedChangesPresent] = useState(false);
    const [currentResponseText, setCurrentResponseText] = useState(component.responseText);
    const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);
    const [showAiComponentEditorModal, setShowAiComponentEditorModal] = useState(false);

    useEffect(() => {
        setCurrentResponseText(component.responseText);
    }, [component.responseText]);

    const updateComponentTextInFirestore = async (componentId, newResponseText) => {
        try {
            console.log("Updating component text in Firestore")
            const componentDocRef = doc(db, 'grantProposalComponents', componentId);
            await updateDoc(componentDocRef, { responseText: newResponseText, updatedAt: new Date() });
            setUnsavedChangesPresent(false); // Reset the unsaved changes indicator
        } catch (error) {
            console.error('Error updating component text: ', error);
            alert('Failed to update component text');
        }
    };

    const handleBackupCreation = async (componentId, newResponseText) => {
        try {
            const backupDocRef = doc(db, 'grantProposalComponentBackups', uuidv4());
            await setDoc(backupDocRef, { 
                componentId: componentId, 
                responseText: newResponseText, 
                createdAt: Timestamp.now(),
            });
            console.log('Backup document created:', backupDocRef.id);
        } catch (error) {
            console.error('Error creating backup document: ', error);
        }
    };

    const debouncedHandleTextUpdate = useCallback(
        debounce(async (componentId, newResponseText) => {
            await updateComponentTextInFirestore(componentId, newResponseText);
            await handleBackupCreation(componentId, newResponseText);
        }, 1000),
        [] // Empty dependency array ensures this is only created once
    );

    const handleTextChange = (componentId, newResponseText) => {
        setUnsavedChangesPresent(true); 
        setCurrentResponseText(newResponseText);
        debouncedHandleTextUpdate(componentId, newResponseText); 
    };

    const handleVersionHistoryButtonClick = () => {
        setShowVersionHistoryModal(true); 
    };    

    return (
        <div className="bg-white p-4 my-8 rounded-lg outline outline-gray-200 outline-1">
            
            {/* Requirement Section */}
            <p className='text-left text-xs font-bold mt-4 ml-1 mb-1'>REQUIREMENT</p>
            <div className='text-left mb-10 font-bold bg-gray-100 rounded-lg px-4 py-4'>
                {component.proposalRequirement}
            </div>

            {/* Response Section */}
            <p className='text-left text-xs font-bold mt-4 ml-1 mb-1'>RESPONSE</p>
            <div className=" bg-orange-100 rounded-lg ">
                <div className="h-50">
                    {unsavedChangesPresent ? <p className="text-red-500 text-right ml-auto mr-4">Unsaved Changes...</p> : <p></p>}
                </div>                                
                <div className="p-6">
                    <div className='mb-12'>
                        <textarea 
                            value={currentResponseText} 
                            onChange={(e) => handleTextChange(component.id, e.target.value)} 
                            className="border border-black mt-4 p-4 w-full h-60" 
                        />
                        <div className="flex space-x-4 float-right">
                            <img 
                                src={history} 
                                onClick={handleVersionHistoryButtonClick} 
                                alt="Show Version History" 
                                className="cursor-pointer w-12 h-12 mt-2 p-2 rounded-lg bg-gray-400 hover:bg-blue-500" 
                            />
                            <img 
                                src={magicWand} 
                                onClick={() => { console.log(component); setShowAiComponentEditorModal(true); }} 
                                alt="AI Edit" 
                                className="cursor-pointer w-12 h-12 mt-2 p-2 rounded-lg bg-gray-400 hover:bg-indigo-500" 
                            />
                            <img 
                                src={trashWhite} 
                                onClick={() => handleProposalComponentDeletion(component.id)} 
                                alt="Delete" 
                                className="cursor-pointer w-12 h-12 mt-2 p-2 rounded-lg bg-gray-400 hover:bg-red-500" 
                            />
                            
                        </div>
                    </div>
                    
                    {showVersionHistoryModal && (
                        <ComponentVersionHistoryModal 
                            componentId={component.id}
                            setShowVersionHistoryModal={setShowVersionHistoryModal}
                        />
                    )}

                    {showAiComponentEditorModal && (
                        <AiComponentEditorModal 
                            setShowAiComponentEditorModal={setShowAiComponentEditorModal}    
                            assistantFiles={assistantFiles}
                            setShowLoadingModal={setShowLoadingModal}
                            currentResponseText={currentResponseText}                                                                                    
                            assistantId={assistantId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProposalTextComponent;