import { useContext, useEffect, useState } from 'react';
import { deleteFileFromOpenAiVectorStore, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../../utilityFunctions/openAiUtilities';
import { markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore, updateNewsletterSubscription, checkSubscription } from '../../utilityFunctions/firestoreUtilities';
import { generateUniqueFileId } from '../../utilityFunctions/uxUtilities';
import { AuthContext } from '../../AuthContext';
import AssistantSettingsPage from './AssistantSettingsPage';
import './AssistantPage.css';

const AssistantPage = ({ 
        setShowKnowledgeBaseModal, 
        currentClientOrganization,
        assistantFiles, 
        setAssistantFiles,
        assistantVectorStoreId,
        assistantId,
        fetchAssistantFiles,
        setShowLoadingModal
    }) => {


    const { authUser } = useContext(AuthContext);
    const [isSubscribed, setIsSubscribed] = useState(false);
    
    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const subscribed = await checkSubscription(authUser);
                setIsSubscribed(subscribed);
            } catch (error) {
                console.error("Error checking subscription status: ", error);
            }
        };

        fetchSubscriptionStatus();
    }, [authUser.uid]);

    async function deleteAssistantFile(fileId) {
        if (window.confirm("Are you sure you want to permanently delete this file?")) {
            try {
                await deleteFileFromOpenAiVectorStore(assistantVectorStoreId, fileId);
                await markFileAsDeletedInFirestore(fileId);
                setAssistantFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
            } catch (error) {
                console.error("Failed to delete assistant file: ", error);
            }
        }
    }

    const handleUserFileUpload = async (file) => { 
        if (!file) {
            alert("No file selected for upload.");
            console.error("No file selected for upload.");
            return;
        }
        
        try {
            console.log("handleUserFileUpload proceeding, file detected")
            setShowLoadingModal(true)
            const uniqueFileId = generateUniqueFileId();
            const downloadURL = await uploadFileToFirebaseStorage(file, uniqueFileId);
            const firestoreRef = await saveFileMetadataToFirestore(uniqueFileId, file.name, downloadURL, authUser.uid);
            const openaiFileId = await uploadFileToOpenAI(file);
            await saveOpenAiFileIdToFirestore(firestoreRef, openaiFileId);
            await addFileToAssistantVectorStore(assistantVectorStoreId, openaiFileId);
    
            fetchAssistantFiles();
            setShowLoadingModal(false)
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };  

    const handleToggleChange = async (e) => {
        const isChecked = e.target.checked;
        setIsSubscribed(isChecked);

        try {
            await updateNewsletterSubscription({
                userId: authUser.uid,
                email: authUser.email,
                clientOrganizationId: currentClientOrganization.id,
                assistantId: assistantId,
                subscribed: isChecked
            });
        } catch (error) {
            console.error("Failed to update newsletter subscription: ", error);
        }
    };

    return (
        <div className='p-12'>
                        
            <div className="mb-12">
                <p className="text-3xl font-bold text-left">Newsletters</p>          
                <div className="text-left w-full bg-indigo-100 rounded p-4 mt-4 p-6 flex justify-between items-center">
                    <div>
                        <p className="text-xl font-bold">Weekly Newsletter</p>
                        <p className="text-gray-600 text-left mt-2">Daisy will search the web for grant opportunities, and notify you if any are a great fit for your organization.</p>
                    </div>
                    <div>
                        <label className="switch mr-4">
                            <input 
                                type="checkbox" 
                                checked={isSubscribed}
                                onChange={handleToggleChange}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>                    
                </div>                
            </div>

            <div className="flex justify-between items-center mb-12">
                <div>
                    <p className="text-3xl font-bold text-left">Knowledge Base</p>               
                    <p className="text-gray-600 text-left mt-2">Daisy will use these documents to assess grant opportunities and generate personalized responses</p>
                </div>                
                <div className="flex items-center space-x-4">
                    <input 
                        type="file" 
                        id="fileInput" 
                        accept=".doc, .docx, .md, .pdf, .txt"
                        style={{ display: 'none' }} 
                        onChange={async (event) => {
                            const file = event.target.files[0];
                            if (file) {
                                await handleUserFileUpload(file); 
                            }
                        }} 
                    />
                    <button 
                        onClick={() => document.getElementById('fileInput').click()} 
                        className="text-white bg-indigo-500 hover:bg-blue-700 text-sm font-bold py-2 px-4 rounded"
                    >
                        Upload
                    </button>
                </div>
            </div>
            <table className="min-w-full text-left divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            File Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created At
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {assistantFiles.map((file, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {file.downloadURL ? (
                                    <a href={file.downloadURL} target="_blank" rel="noopener noreferrer" className="text-sm text-indigo-600 hover:underline">
                                        {file.fileName}
                                    </a>
                                ) : (
                                    <div className="text-sm text-gray-900">{file.fileName}</div>
                                )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-500">{new Date(file.created_at * 1000).toLocaleString()}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-sm ">
                                <button onClick={() => deleteAssistantFile(file.id)} className="text-red-300 hover:text-red-600">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <AssistantSettingsPage 
                    assistantId={assistantId}
                    assistantVectorStoreId={assistantVectorStoreId}
                />
            </div>
        </div>
    );
};

export default AssistantPage;
